import React from "react";
import swal from "sweetalert";
import "./index.scss";
import Api from "../../services/api";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

class Contato extends React.Component {
  state: any = {};
  api: any = null;

  constructor(props) {
    super(props);

    this.api = new Api();

    this.state = {
      contatoNome: "",
      contatoEmail: "",
      contatoCelular: "",
      contatoMensagem: "",
      contatoIsLoading: false,
    };
  }

  private async _handleSubmitContato(event) {
    event.preventDefault();
    const self = this;

    try {
      if (
        !self.state.contatoNome ||
        !self.state.contatoEmail ||
        !self.state.contatoCelular ||
        !self.state.contatoMensagem
      ) {
        throw new Error("Formulário incompleto");
      }

      self.setState({ contatoIsLoading: true });

      const paramApi = {
        email: self.state.contatoEmail,
        nome: self.state.contatoNome,
        celular: self.state.contatoCelular,
        mensagem: self.state.contatoMensagem,
      };

      const { data } = await self.api.post(`/contact`, paramApi);

      if (!data.status) {
        throw new Error(data.msg);
      }

      swal("Sucesso", "Mensagem enviada com sucesso!", "success");

      self.setState({
        contatoNome: "",
        contatoEmail: "",
        contatoCelular: "",
        contatoMensagem: "",
      });
    } catch (e) {
      swal("Atenção", e.message, "error");
    } finally {
      self.setState({ contatoIsLoading: false });
    }
  }

  render() {
    return (
      <>
        <Header />

        <div className="container-fluid my-5 px-4 container-contato">
          <p className="d-none d-md-block">&nbsp;</p>
          <p className="d-none d-md-block">&nbsp;</p>
          <h1 className="text-center"> Fale Conosco </h1>

          <div className="row">
            <div className="col-12 col-md-4 offset-md-2 offset-0 text-center">
              <h3 className="text-left">Contato</h3>
              <h4 className="text-left">(41) 3276-0764</h4>
              <form
                className="form"
                onSubmit={this._handleSubmitContato.bind(this)}
                method="post"
              >
                <div className="form-group text-left">
                  <label htmlFor="nome">Nome</label>
                  <input
                    type="text"
                    name="nome"
                    id="nome"
                    className="form-control"
                    value={this.state.contatoNome}
                    onChange={(event) =>
                      this.setState({ contatoNome: event.target.value })
                    }
                    disabled={this.state.contatoIsLoading}
                  />
                </div>
                <div className="form-group text-left">
                  <label htmlFor="email">E-mail</label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className="form-control"
                    value={this.state.contatoEmail}
                    onChange={(event) =>
                      this.setState({ contatoEmail: event.target.value })
                    }
                    disabled={this.state.contatoIsLoading}
                  />
                </div>
                <div className="form-group text-left">
                  <label htmlFor="celular">Celular</label>
                  <input
                    type="text"
                    name="celular"
                    id="celular"
                    className="form-control"
                    value={this.state.contatoCelular}
                    onChange={(event) =>
                      this.setState({ contatoCelular: event.target.value })
                    }
                    disabled={this.state.contatoIsLoading}
                  />
                </div>
                <div className="form-group text-left">
                  <label htmlFor="msg">Mensagem</label>
                  <textarea
                    name="msg"
                    id="msg"
                    className="form-control"
                    rows={5}
                    value={this.state.contatoMensagem}
                    onChange={(event) =>
                      this.setState({ contatoMensagem: event.target.value })
                    }
                    disabled={this.state.contatoIsLoading}
                  >
                    {this.state.contatoMensagem}
                  </textarea>
                </div>

                <button
                  type="submit"
                  className="btn btn-primary btn-block btn-lg"
                  disabled={this.state.contatoIsLoading}
                >
                  {this.state.contatoIsLoading ? (
                    <>
                      <i className="fas fa-spinner fa-spin"></i>
                      Enviando mensagem...
                    </>
                  ) : (
                    <>
                      <i className="fas fa-envelope"></i>
                      Enviar Mensagem
                    </>
                  )}
                </button>
              </form>
            </div>

            <div className="col-12 col-md-4 text-left mt-5 mt-md-0">
              <h3 className="text-left">Localização</h3>
              <p>Rua Anne Frank, 653 - Hauer - Curitiba - PR - CEP 81610-020</p>
              <iframe
                title="Maps endereço Cipry"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3601.984167014338!2d-49.252474384103884!3d-25.472204140800912!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94dce4da7145cca9%3A0x133197ff83c5c089!2sRua%20Anne%20Frank%2C%20653%20-%20Hauer%2C%20Curitiba%20-%20PR%2C%2081610-020!5e0!3m2!1spt-BR!2sbr!4v1669909493255!5m2!1spt-BR!2sbr"
                width="600"
                height="450"
                allowFullScreen={false}
                // loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                style={{
                  border: 0,
                  maxWidth: "100%",
                }}
              ></iframe>
            </div>
          </div>
        </div>

        <Footer />
      </>
    );
  }
}

export default Contato;
