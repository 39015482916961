import React from "react";
import "./index.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faFacebookSquare } from "@fortawesome/free-brands-svg-icons";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import Tecworks from "../../assets/Images/tecworks.png";
import logo from "../../assets/Images/logo.png";

class Footer extends React.Component<{}> {
  state: any = null;

  constructor(props) {
    super(props);

    this.state = {
      newsletterMail: "",
    };
  }

  render() {
    return (
      <>
        <footer>
          <div className="footer-content">
            <div className="logo-container pt-5">
              <img src={logo} />
            </div>
            <div className="links-container">
              <p> Institucional </p>
              <a href="/sobre"> Sobre nós </a>
              <a href="/servicos"> Serviços </a>
              <a href="/filiais"> Filiais </a>
              <a href="/contato"> Contato </a>
            </div>
            <div className="links-container">
              <p> Endereço </p>
              <div className="endereco">
                <FontAwesomeIcon
                  icon={faMapMarkerAlt}
                  className="footer-icon"
                />
                Rua Anne Frank, 653 - Hauer CEP 81610-020
              </div>
            </div>
            <div className="links-container">
              <p> Contato </p>
              <div className="numero">
                <FontAwesomeIcon icon={faWhatsapp} className="footer-icon" />
                (41) 3276-0764
              </div>
              <div className="numero">
                <FontAwesomeIcon icon={faEnvelope} className="footer-icon" />
                comercial@cipry.com.br
              </div>
            </div>

            <div className="links-container">
              <div className="social">
                <p> Redes Sociais </p>
                <a
                  href="https://www.instagram.com/cipry/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faInstagram} className="footer-icon" />
                </a>
                <a
                  href="https://www.facebook.com/cipry/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  <FontAwesomeIcon
                    icon={faFacebookSquare}
                    className="footer-icon"
                  />{" "}
                </a>
              </div>
            </div>
          </div>
          <p className="copyright">
            Cipry Transportes
            <br />
            Desenvolvido por Grupo TecWorks{" "}
            <img src={Tecworks} alt="logo Tecworks" />
          </p>
        </footer>
      </>
    );
  }
}

export default Footer;
