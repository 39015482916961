import React from "react";
import "./index.scss";

import PageNotFound from "../../assets/Images/page-not-found.png";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";

class Filial extends React.Component {
  render() {
    return (
      <>
        <Header />

        <Container fluid className="my-5 px-4 container-filial">
          <p className="d-none d-md-block">&nbsp;</p>
          <p className="d-none d-md-block">&nbsp;</p>
          <h1> Filiais </h1>
        </Container>
        <Footer />
      </>
    );
  }
}

export default Filial;
