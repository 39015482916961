/**
 * ACTIONS TYPES
 */
export enum CartTypes {
  ADD = '@repositories/ADD',
  DROP = '@repositories/DROP',
  UPDATE = '@repositories/UPDATE',
  CLEAN = '@repositories/CLEAN'
}

export interface ProductType {
  rowid?: string;
  codigo: number;
  nome: string;
  price: number;
  qty: number;
}

/**
 * Data types
 */
export interface Cart {
  produtos: Array<ProductType>
}

/**
 * State types
 */
export interface CartState {
  readonly data: Cart
}