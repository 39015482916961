import { Reducer } from 'redux';
import { v4 as uuidv4 } from 'uuid';
import { CartState, CartTypes, ProductType } from './types';

const INITIAL_STATE: CartState = {
  data: {
    produtos: []
  },
}

const reducer: Reducer<any> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    
    // FUNCAO PARA ADICIONAR PRODUTO NO CARRINHO
    case CartTypes.ADD:
      let tmp = state.data.produtos;
      tmp.push( {...action.payload.data, rowid: uuidv4()}  );
      return { 
        ...state, 
        data: {produtos: tmp}
      };
    
    // FUNCAO PARA APAGAR PRODUTO NO CARRINHO
    case CartTypes.DROP:
      let produtos = state.data.produtos;
      let tmpDrop: Array<ProductType> = [];
      for ( var i = 0; i < produtos.length; i++ ) {
        if ( produtos[i].rowid !== action.payload.rowid ) {
          tmpDrop.push( produtos[i] );
        }
      }
      return { 
        ...state,
        data: {produtos: tmpDrop}
      };

    // FUNCAO PARA APAGAR PRODUTO NO CARRINHO
    case CartTypes.UPDATE:
      let produtosEdit = state.data.produtos;
      for ( var i = 0; i < produtosEdit.length; i++ ) {
        if ( produtosEdit[i].rowid === action.payload.data.rowid ) {
          produtosEdit[i] = { ...produtosEdit[i], ...action.payload.data };
        }
      }
      return { 
        ...state,
        data: {produtos: produtosEdit}
      };

    case CartTypes.CLEAN:
      return { 
        ...state,
        data: {produtos: []}
      };

    default:
      return state;
  }
}

export default reducer;