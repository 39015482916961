import React from "react";
import "./index.scss";
import HorizontalScroll from "react-scroll-horizontal";
import PageNotFound from "../../assets/Images/page-not-found.png";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Container from "react-bootstrap/Container";

class Premio extends React.Component {
  render() {
    return (
      <>
        <Header />

        <Container fluid className="my-5 px-4 text-center container-premio">
          <p className="d-none d-md-block">&nbsp;</p>
          <p className="d-none d-md-block">&nbsp;</p>
          <h1 className="text-center"> PRÊMIOS </h1>
          <p>&nbsp;</p>

          <p>
            A CIPRY TRANSPORTES é premiada pela sua excelência operacional e de
            seus colaboradores, sendo reconhecida como uma empresa de alto nível
            de serviço.
            <br />
            Resultado de muita dedicação e empenho em ter no DNA a missão de ser
            a melhor escolha de nossos Clientes.
          </p>
          <p>&nbsp;</p>
          <div className="d-flex flex-row align-items-center justify-content-center flex-wrap flex-md-nowrap">
            <img
              src={require("../../assets/Images/cipry-premio-img-1.png")}
              alt="Cipry | Prêmios | Img 1"
              className="mb-5 mb-md-0"
            />
            <img
              src={require("../../assets/Images/cipry-premio-img-2.png")}
              alt="Cipry | Prêmios | Img 2"
              className="mb-5 mb-md-0"
            />
            <img
              src={require("../../assets/Images/cipry-premio-img-3.jpg")}
              alt="Cipry | Prêmios | Img 3"
              className="mb-5 mb-md-0"
            />
            <img
              src={require("../../assets/Images/cipry-premio-img-4.png")}
              alt="Cipry | Prêmios | Img 4"
              className="mb-5 mb-md-0"
            />
            <img
              src={require("../../assets/Images/cipry-premio-img-5.jpg")}
              alt="Cipry | Prêmios | Img 5"
              className="mb-5 mb-md-0"
            />
          </div>

          <p>&nbsp;</p>
          <p>&nbsp;</p>
        </Container>

        <Footer />
      </>
    );
  }
}

export default Premio;
