import React from "react";
import "./index.scss";

import PageNotFound from "../../assets/Images/page-not-found.png";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";

class Sobre extends React.Component {
  render() {
    return (
      <>
        <Header />

        <Container fluid className="my-5 px-5 w-100 container-sobre">
          <p className="d-none d-md-block">&nbsp;</p>
          <p className="d-none d-md-block">&nbsp;</p>
          <h1 className="text-center"> Sobre </h1>

          <h3>Visão geral do mercado</h3>
          <p>
            O Brasil é um país eminentemente rodoviário, principal meio da
            atividade logística e responsável por movimentar 65% das riquezas de
            nosso país, segmento esse que é desafiado diariamente por novas e
            melhores soluções.
          </p>
          <p>
            Dentre as etapas do processo logístico encontram-se o first mile,
            middle mile e last mile, sendo este último a principal operação
            realizada pela CIPRY TRANSPORTES, que se tornou uma das fases mais
            importantes do Transporte Rodoviário de Cargas, vez que é a chegada
            do produto no cliente final, que pode recebê-lo na sua residência,
            no trabalho ou em qualquer outro local indicado no momento da
            compra. É nesta etapa que pode ser medido o grau de satisfação do
            cliente, baseado no cumprimento do prazo, condições da embalagem,
            regras de segurança e cordialidade do entregadores.
          </p>
          <p>
            Com o comércio eletrônico ou e-commerce o last mile ganhou evidência
            e tornou-se ainda mais importante no ecossistema logístico. O last
            mile é responsável por criar uma experiência duradoura com o
            cliente, é ferramenta de fidelização e diferenciação, quando se
            encerra o processo de compra.
          </p>

          <h3 className="mt-4">Jeito Cipry de ser</h3>
          <p>
            Ser a melhor solução logística que conecta pessoas e empresas, com
            inovação, qualidade, ética e responsabilidade socioambiental é a
            nossa visão. Prezamos por entregar soluções logísticas com geração
            de valor e encantar o cliente, promovendo conectividade,
            transparência, pontualidade, flexibilidade, valorizando e
            respeitando pessoas, sempre atuando com responsabilidade social.
          </p>
          <p>
            Diversificar, Conectar e Integrar pessoas, negócios e oportunidades,
            gerando o máximo de valor para nossos clientes, colaboradores,
            fornecedores e sociedade é nosso propósito.
          </p>

          <h3 className="mt-4">VISÃO</h3>
          <p>
            Ser a melhor solução logística que conecta pessoas e empresas, com
            inovação, qualidade, ética e responsabilidade socioambiental.
          </p>

          <h3 className="mt-4">MISSÃO </h3>
          <p>Entregar soluções logísticas com geração de valor.</p>

          <h3 className="mt-4">VALORES </h3>
          <p>
            <ul>
              <li>Encantar o cliente</li>
              <li>Conectividade</li>
              <li>Transparência </li>
              <li>Flexibilidade</li>
              <li>Pontualidade</li>
              <li>Valorização e respeito às Pessoas</li>
              <li>Responsabilidade socioambiental</li>
            </ul>
          </p>

          <h3 className="mt-4">PRÓPOSITO</h3>
          <p>
            Diversificar, Conectar e Integrar pessoas, negócios e oportunidades,
            gerando o máximo de valor para nossos clientes, colaboradores,
            fornecedores e sociedade.
          </p>

          <h3 className="mt-4">História da empresa</h3>
          <p>
            A história da CIPRY TRANSPORTES iniciou em 1996, em Curitiba – PR,
            com objetivo de realizar, com excelência, as operações B2B e B2C, no
            Sul do País. Nos primeiros anos dedicou-se as soluções logísticas
            para o segmento de cosméticos e produtos de higiene pessoal,
            ampliando seu portfólio mais tarde para os principais segmentos do
            mercado. Em 2011 com a necessidade de expandir, a CIPRY inaugurou
            suas unidades em Londrina e Cascavel, consolidando-se em soluções
            logísticas especiais e customizadas, aliadas à qualidade, segurança
            e preço justo. A CIPRY busca constantemente inovar seus processos, a
            fim de satisfazer e superar as expectativas de seus clientes,
            mantendo altos índices de SLA, com a melhor Experiência do Cliente.
            A CIPRY mantém relações humanizadas com seus colaboradores e
            parceiros, o que permite a formação de uma equipe profissionalizada
            e experiente, com baixíssimo turnover, agregando valor aos clientes
            e a sociedade. Comunicação assertiva, informações integradas, foco
            do cliente, segurança, consultoria (ANTT, vale-pedágio, CIOT, dentre
            outros temas correlacionados), tecnologia e cultura da inovação,
            ESG, relações humanizadas, excelência operacional, com destaque aos
            KPIs fazem parte do propósito da CIPRY TRANSPORTES. CIPRY possui um
            processo sucessório estruturado, com a participação da segunda
            geração, nas Diretorias Operacional e Administrativa. Ainda, optou
            pelo ingresso de Executiva de Estratégia do segmento de transporte
            rodoviário de cargas para reforçar a gestão.
          </p>

          <h3 className="mt-4">Timeline</h3>
          <p className="text-center">
            <img
              src={require("../../assets/timeline.png")}
              alt="Timeline Cipry"
              style={{
                maxWidth: "100%",
              }}
            />
          </p>

          <h3 className="mt-4">Atuação</h3>
          <p>
            É uma empresa com foco em projetos especiais e customizados.
            <br />
            Especializada em entregas B2B, B2C, last mile standard, expresso e
            calendarizado, Logística reversa, Fulfillment e Cross Docking, FTL
            (Fracionados compartilhados, Indoor e Field Services)
          </p>
          <p>
            Os processos são de alta eficiência, equipe indoor altamente
            qualificada e constantemente reciclada, atendimento personalizado
            para o cliente, alinhados a legislação vigente e amparado por
            responsáveis técnicos altamente qualificados, proporcionando a
            melhor experiência do cliente.
          </p>

          <h3 className="mt-4">Estrutura</h3>
          <p>
            A sede principal da organização é em Curitiba, com filiais
            estratégicas em Londrina e Cascavel.
            <br />
            Cipry atende todo Estado do Paraná e Santa Catarina, com mais de 710
            localidades, entre municípios e distritos.
            <br />
            Rastreabilidade de 100% dos pedidos faz parte das operações da
            CIPRY.
            <br />
            Utilização de mobile para interação e finalização das entregas
          </p>
          <p className="text-center">
            <img
              src={require("../../assets/estrutura-tabela.png")}
              alt="Estrutura da Cipry"
              style={{
                maxWidth: "100%",
              }}
            />
          </p>

          <h3 className="mt-4">Conexões</h3>
          <p>
            A Cipry preza muito por seus colaboradores e parceiros de negócios,
            propiciando um ambiente saudável, com segurança emocional,
            valorização das pessoas e escuta ativa, o que resulta em um turn
            over baixo (muito abaixo do segmento de transporte) e equipe
            especializada.
          </p>
        </Container>

        <Footer />
      </>
    );
  }
}

export default Sobre;
