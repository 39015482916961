import React from "react";
import "./index.scss";

import PageNotFound from "../../assets/Images/page-not-found.png";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";

class Certificado extends React.Component {
  render() {
    return (
      <>
        <Header />

        <Container
          fluid
          className="my-5 px-4 w-100 w-md-50 text-center container-certificado"
        >
          <p className="d-none d-md-block">&nbsp;</p>
          <p className="d-none d-md-block">&nbsp;</p>
          <h1 className="text-center"> LICENÇAS E CERTIFICAÇÕES </h1>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p className="text-left text-md-center">
            <ul>
              <li>Alvarás</li>
              <li>ANVISA e VISA</li>
              <li>Responsável técnico | Curitiba – CRF/PR 26091</li>
              <li>Responsável técnico | Londrina – CRF/PR 24005</li>
              <li>Responsável técnico | Cascavel – CRQ/PR 09201704</li>
              <li>ANTT</li>
              <li>CND Federal</li>
              <li>CND Estado do Paraná</li>
              <li>CND Municipais</li>
              <li>CRF – Certidão de Regularidade do FGTS</li>
              <li>Apólices de seguro RCTRC, empresarial e frota </li>
            </ul>
          </p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
        </Container>

        <Footer />
      </>
    );
  }
}

export default Certificado;
