import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import HorizontalScroll from "react-scroll-horizontal";
import { ApplicationState } from "../../store";
import { User, UserTypes } from "../../store/ducks/user/types";
import * as UserActions from "../../store/ducks/user/actions";
import { Param, ParamTypes } from "../../store/ducks/param/types";
import * as ParamActions from "../../store/ducks/param/actions";
import Api from "../../services/api";
import "./index.scss";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Van from "../../assets/Images/van.png";
import Pin from "../../assets/Images/pin.png";
import Time from "../../assets/Images/time.png";
import Quality from "../../assets/Images/quality.png";
import Bank from "../../assets/Images/piggy.png";
import Support from "../../assets/Images/support.png";

const documentWidth = document.documentElement.clientWidth;
const windowWidth = window.innerWidth;
const scrollBarWidth = windowWidth - documentWidth;

interface StateProps {
  user: User;
  param: Param;
}

interface DispatchProps {
  doLogin(user: User): void;
  setParam(param: Param): void;
}

interface OwnProps {}

function BenefitCard(props) {
  return (
    <div className="benefit-card">
      {/* <img src={props.src} /> */}
      <div>
        <p> {props.title} </p>
        <p> {props.content} </p>
      </div>
    </div>
  );
}

class Home extends Component<StateProps & DispatchProps & OwnProps> {
  api: any = null;
  state: any = null;

  constructor(props) {
    super(props);
    this.api = new Api();
    this.state = {
      animateVan: false,
    };
  }

  componentDidMount() {
    this.setState({ animateVan: true });
  }

  render() {
    return (
      <>
        <Header />

        <section className="cta">
          <div className="cta">
            <h5> Cipry transportes </h5>
            <h1> Excelência operacional e de seus colaboradores </h1>
            <a href="/contato" className="btn-primary btn-lg">
              {" "}
              Entrar em contato{" "}
            </a>
            <p> 1 </p>
          </div>
          <div className="van-container d-none d-md-block">
            <img src={Pin} className="pin" />
            <img src={Van} className="van" />
          </div>
        </section>

        <section className="benefits">
          <h2>
            {" "}
            nossos diferenciais <span> jeito Cipry de ser </span>{" "}
          </h2>
          <article className="benefits-row">
            <HorizontalScroll>
              <BenefitCard
                src={Time}
                title="Histórico de sucesso em last mile / e-commerce
              "
                content=""
              />
              <BenefitCard
                src={Quality}
                title="Equipe especializada e com experiência em e-commerce"
                content=""
              />
              <BenefitCard
                src={Bank}
                title="Experiência com o ecossistema de cosméticos e produtos de higiene pessoal há mais de 49 anos"
                content=""
              />
              <BenefitCard
                src={Support}
                title="Baixo turnover da equipe"
                content=""
              />
              <BenefitCard
                src={Support}
                title="Prazos com foco do cliente, respeitando Lei de Trânsito, Lei do Motorista e distância entre ponto A ao ponto B"
                content=""
              />
              <BenefitCard
                src={Support}
                title="Baixo índice de avarias ou inversões"
                content=""
              />
              <BenefitCard
                src={Support}
                title="Costumer Experience"
                content=""
              />
              <BenefitCard
                src={Support}
                title="Projetos customizados com ênfase nas relações ganha-ganha"
                content=""
              />
              <BenefitCard
                src={Support}
                title="Capacidade operacional"
                content=""
              />
              <BenefitCard
                src={Support}
                title="Gestão de risco em todas as etapas do processo, incluindo legislação vigente"
                content=""
              />
              <BenefitCard
                src={Support}
                title="Empresa auditada e homologada pela Natura & CO"
                content=""
              />
              <BenefitCard
                src={Support}
                title="Empresa adaptada a LGPD – Empresa responsável e comprometida com a privacidade de seus dados"
                content=""
              />
            </HorizontalScroll>
          </article>

          <section className="partners text-center">
            <img
              src={require("../../assets/Images/cipry-logo-grande.png")}
              alt="Cipry"
            />
            {/* <h2>
              {" "}
              Conheça alguns de nossos <span> Parceiros </span>{" "}
            </h2> */}
          </section>
        </section>

        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.data,
  param: state.param.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...UserActions, ...ParamActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Home);
