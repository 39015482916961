import React from "react";
import "./index.scss";

import PageNotFound from "../../assets/Images/page-not-found.png";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";

class Esg extends React.Component {
  render() {
    return (
      <>
        <Header />

        <Container fluid className="my-5 px-4 container-esg">
          <p className="d-none d-md-block">&nbsp;</p>
          <p className="d-none d-md-block">&nbsp;</p>

          <h1 className="text-center">
            ESG - Environmental, Social, and Corporate Governance
          </h1>
          <p>
            O programa Programa Cipry Eco Mode On Nosso projeto CIPRY ECO MODE
            ON, iniciado em 2022, tem como escopo reforçar a cultura ESG e
            processos de inovação na organização, com foco nas pessoas e na
            preservação do planeta. Está dividido em três pilares fundamentais,
            quais sejam, veículos menos poluentes, energia renovável e
            autoridade para longevidade.
          </p>
          <p>
            Os benefícios do projeto estão atrelados ao reforço da autoridade
            para longevidade e cultura organizacional, com compromisso mundial
            para diminuir impactos ambientais, da integração do colaborador
            quando de seu ingresso, passando pelo código de conduta e políticas
            corporativas, capacitação contínua até a contratação de fornecedores
            que compartilham dos mesmos interesses e valores, envolvendo assim
            todos os stakeholders da cadeia produtiva, trazendo consciência
            sustentável.
          </p>
          <div className="row">
            <div className="col-12 col-md-6 d-flex flex-column align-items-center justify-content-center">
              <p>
                Também fazem parte do Projeto CIPRY ECO MODE ON:
                <ul>
                  <li>Coleta seletiva e gerenciamento de resíduos</li>
                  <li>
                    Eficiência hídrica: torneiras automáticas e garrafa pet nas
                    caixas dos vasos sanitários
                  </li>
                  <li>Canecas e garrafas reutilizáveis</li>
                  <li>Luz com sensores de presença</li>
                  <li>Processos digitais e uso mínimo de papéis</li>
                  <li>Reciclagem de insumos</li>
                  <li>
                    Parcerias para delivery com bicicletas convencionais em
                    capitais
                  </li>
                  <li>Uso de veículos movidos a GNV</li>
                  <li>
                    Teste de opacidade em 100% da frota própria e agregada
                  </li>
                  <li>
                    Empresa socialmente responsável: Participação na
                    distribuição de cestas básicas nas unidades operacionais de
                    Curitiba, Londrina e Cascavel
                  </li>
                </ul>
              </p>
            </div>
            <div className="col-12 col-md-6 d-flex flex-column align-items-center justify-content-center">
              <img
                src={require("../../assets/Images/cipry-esg-img1.jpg")}
                alt="Cipry | ESG"
                style={{
                  width: 250,
                  height: "auto",
                  objectFit: "contain",
                }}
              />
              <img
                src={require("../../assets/Images/cipry-esg-img2.jpg")}
                alt="Cipry | Sustentável"
                style={{
                  width: 275,
                  height: "auto",
                  objectFit: "contain",
                }}
              />
            </div>
          </div>

          <p>
            <ul>
              <li>Liderança feminina na gestão administrativa e operacional</li>
              <li>Protagonismo feminino em todos os níveis hierárquicos </li>
              <li>
                <strong style={{ fontSize: 28 }}>23,96%</strong> de mulheres no
                quadro funcional e nos prestadores de serviços
              </li>
              <li>
                Participação em movimentos que valorizam a inclusão de mulheres
                no setor de transporte rodoviário de transporte rodoviário de
                cargas
              </li>
            </ul>
          </p>
        </Container>

        <Footer />
      </>
    );
  }
}

export default Esg;
