import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { ApplicationState } from "../../store";
import { User, UserTypes } from "../../store/ducks/user/types";
import * as UserActions from "../../store/ducks/user/actions";
import { Param, ParamTypes } from "../../store/ducks/param/types";
import * as ParamActions from "../../store/ducks/param/actions";
import "./index.scss";
import logo from "../../assets/Images/logo.png";
import { Redirect } from "react-router-dom";
import Api from "../../services/api";
import { Diversos } from "../../services/diversos";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import MultilevelSidebar from "react-multilevel-sidebar";
import "react-multilevel-sidebar/src/Sidebar.css";
import Container from "react-bootstrap/Container";
declare var window;

interface StateProps {
  user: User;
  param: Param;
}

interface DispatchProps {
  doLogin(user: User): void;
  setParam(param: Param): void;
}

interface OwnProps {
  searchTermo?: string;
  tipo?: number;
}

type Props = StateProps & DispatchProps & OwnProps;

class Header extends React.Component<Props> {
  api: any = null;
  state: any = null;
  searchbarRef: any;
  listener: any;

  constructor(props) {
    super(props);
    this.api = new Api();
    this.searchbarRef = React.createRef();
    this.state = {
      isLoading: false,
      menu: null,
      searchTerm: this.props.searchTermo ? this.props.searchTermo : null,
      searchbarHasError: false,
      redirect: null,
      headerStatus: "top",
      sidebarOpened: false,
      options: [
        {
          title: "Cipry",
          content: [
            { id: 1, to: "/", name: "Home" },
            { id: 1, to: "/sobre", name: "Sobre nós" },
            { id: 1, to: "/servicos", name: "Serviços" },
            { id: 1, to: "/esg", name: "ESG" },
            {
              id: 1,
              to: "/licencas-e-certificados",
              name: "Licenças e Certificados",
            },
            { id: 1, to: "/premios", name: "Prêmios" },
            { id: 1, to: "/contato", name: "Contato" },
          ],
        },
      ],
    };
  }

  async componentDidMount() {
    const self = this;

    self.setState({ isLoading: true });

    try {
      const { data } = await self.api.get("/menu/nivel1");

      if (data.status === false) {
        throw new Error("Nao foi possível recuperar menu");
      } else {
        let aux: any = [];
        for (var i = 0; i < data.msg.length; i++) {
          aux.push({
            codigo: data.msg[i].CDMENU,
            descricao: data.msg[i].DESCRICAO,
          });
        }
        self.setState({ menu: aux });
      }
    } catch (e) {
      console.error(e);
      self.setState({ menu: null });
    } finally {
      self.setState({ isLoading: false });
    }

    if (window.pageYOffset >= 90) {
      this.setState({ headerStatus: "" });
    }

    this.listener = document.addEventListener("scroll", (e) => {
      if (document.scrollingElement === null) {
        alert("scrollingElement é null");
      } else {
        var scrolled = document.scrollingElement.scrollTop;
        if (scrolled >= 90) {
          if (this.state.headerStatus !== "") {
            this.setState({ headerStatus: "" });
          }
        } else if (this.state.headerStatus !== "top")
          this.setState({ headerStatus: "top" });
      }
    });
  }

  componentDidUpdate() {
    document.removeEventListener("scroll", this.listener);
  }

  handleSidebarToggle = (isOpen) => {
    this.setState({ sidebarOpened: isOpen });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    return (
      <>
        <header>
          <nav className="nav">
            <button
              className="d-block d-md-none"
              onClick={() => this.setState({ sidebarOpened: true })}
            >
              <FontAwesomeIcon icon={faBars} />
            </button>
            <a href="/">
              {" "}
              <img src={logo} />{" "}
            </a>
            <ul className="d-none d-md-flex">
              <li>
                {" "}
                <a href="/"> Home </a>{" "}
              </li>
              <li>
                {" "}
                <a href="/sobre"> Sobre nós </a>{" "}
              </li>
              <li>
                {" "}
                <a href="/servicos"> Serviços </a>{" "}
              </li>
              <li>
                {" "}
                <a href="/esg"> ESG </a>{" "}
              </li>
              <li>
                {" "}
                <a href="/licencas-e-certificados">
                  {" "}
                  Licenças e Certificados{" "}
                </a>{" "}
              </li>
              <li>
                {" "}
                <a href="/premios"> Prêmios </a>{" "}
              </li>
              <li style={{ height: "100%", marginRight: 0 }}>
                {" "}
                <a href="/contato" className="btn btn-primary">
                  {" "}
                  Contato{" "}
                </a>{" "}
              </li>
            </ul>
          </nav>
        </header>

        <MultilevelSidebar
          open={this.state.sidebarOpened}
          onToggle={this.handleSidebarToggle}
          options={this.state.options}
          wrapperClassName="sidebar-mobile-menu"
          header={
            <>
              <Container className="sidebar-header"></Container>
            </>
          }
        />
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.data,
  param: state.param.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...UserActions, ...ParamActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Header);
