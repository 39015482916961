import React, { Component } from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import {
  BrowserRouter,
  Route,
  Link,
  RouteComponentProps,
  Switch,
  Redirect,
} from "react-router-dom";
import { store, persistor } from "../store";
import "./App.scss";

import Home from "./Home";
import NoMatchPage from "./NoMatchPage";
import Sobre from "./Sobre";
import Servico from "./Servico";
import Esg from "./Esg";
import Certificado from "./Certificado";
import Premio from "./Premio";
import Filial from "./Filial";
import Contato from "./Contato";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
library.add(fab, fas);

class App extends Component<{}> {
  state: any = {};

  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <Switch>
              {/* DECLARA AS ROTAS USADAS NO PROJETO */}
              <Route path="/" exact component={Home} />
              <Route path="/sobre" exact component={Sobre} />
              <Route path="/servicos" exact component={Servico} />
              <Route path="/esg" exact component={Esg} />
              <Route
                path="/licencas-e-certificados"
                exact
                component={Certificado}
              />
              <Route path="/premios" exact component={Premio} />
              <Route path="/filiais" exact component={Filial} />
              <Route path="/contato" exact component={Contato} />
              <Route component={NoMatchPage} />
            </Switch>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    );
  }
}

export default App;
