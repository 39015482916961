import React from "react";
import "./index.scss";

import PageNotFound from "../../assets/Images/page-not-found.png";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";

class Servico extends React.Component {
  render() {
    return (
      <>
        <Header />

        <Container fluid className="my-5 px-4 container-servico">
          <p className="d-none d-md-block">&nbsp;</p>
          <p className="d-none d-md-block">&nbsp;</p>
          <h1 className="text-center"> Serviços </h1>

          <h3>Soluções em Transporte | CX</h3>
          <p>
            <strong>COSTUMER EXPERIENCE CIPRY</strong> acompanha as operações de
            transporte, do início ao fim, tratando on time de todas as etapas do
            processo, com o objetivo de identificar, prevenir e solucionar as
            ocorrências com os produtos movimentados, proporcionando
            continuamente a melhor experiência para seus Clientes.
          </p>
          <div className="d-flex flex-row align-items-center justify-content-around">
            <div>
              <ul>
                <li>Relações Humanizadas</li>
                <li>Personalização</li>
                <li>Agilidade</li>
                <li>Visibilidade</li>
                <li>Produtividade</li>
                <li>Padronização</li>
                <li>Engajamento e fidelização</li>
              </ul>
            </div>
            <div>
              <img
                src={require("../../assets/Images/cipry-solucoes-em-transporte-cx.jpg")}
                alt="Cipry | Soluções em transporte | CX"
                style={{
                  width: 500,
                  height: "auto",
                  objectFit: "contain",
                }}
              />
            </div>
          </div>
        </Container>

        <Footer />
      </>
    );
  }
}

export default Servico;
